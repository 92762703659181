<template>
    <el-dialog
        class="editDialog"
        :append-to-body="true"
        width="600px"
        :visible.sync="showEditDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        @close="$emit('update:isShow', false)"
        :show-close="true"

    >

        <div slot="title" class="title">角色关联</div>

        <div class="content" v-loading="loading">
            <el-transfer
                v-model="myUserRoles"
                :data="ownRoles"
                :titles="['未关联的角色','已关联的角色']">
            </el-transfer>
        </div>

        <div slot="footer" v-show="!loading">
            <el-button type="primary" :loading="submitLoading" @click="submit">保存</el-button>
            <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
        </div>

    </el-dialog>

</template>
<script>

export default {
    props: {
        isShow: {
            type: Boolean,
            default: false
        },
        options: {
            type: Object,
            default:function(){
                return {}
            }
        },
        listData: {
            type: Array,
            default:function(){
                return []
            }
        },
        refresh: {
            type: Function,
            default: () => {}
        },
    },
    data() {
        return {
            showEditDialog: false,
            loading: true,
            submitLoading: false,
            ownRoles:[],
            myUserRoles:[],
        };
    },
    watch: {
        isShow: {
            immediate: true,//初始化立即执行
            handler: function (newVal) {
                this.showEditDialog = newVal;
            }
        },
        options:{
            immediate:true,//初始化立即执行
            deep: true,//对象深度监测
            handler: function (newVal,oldVal){
                if(newVal.id){
                    this.init();
                }
            }
        }
    },
    created() {},
    methods: {
        init(){
            this.loading = true;
            this.ownRoles = [];
            this.myUserRoles = [];
            this.$api['admin/user'].findRoleByUserId(this.options.id).then(res => {
                res.data.ownRoles.forEach(item=>{
                    this.ownRoles.push({
                        key:item.id,
                        label:item.name,
                    })
                });
                res.data.myUserRoles.forEach(item=>{
                    this.ownRoles.push({
                        key:item.id,
                        label:item.name,
                    })
                    this.myUserRoles.push(item.id)
                });
                this.loading = false;

            }).catch(err => {
                this.loading = false;
                console.log(err);
            })
        },
        submit(){
            this.submitLoading = true;
            this.$api['admin/user'].saveUserRole({
                userId: this.options.id,
                roleIds: this.myUserRoles,
            }).then(res => {
                this.submitLoading = false;
                this.refresh();
                this.$message.success("操作成功！");
            }).catch(err => {
                this.submitLoading = false;
                console.log(err);
            })
        },

    },
};
</script>
